<template>
  <div>
    <v-card elevation="0" style="border:1px solid #EAEBEE">
      <v-card-title class="text-body-1 mx-4" style="height:70px"
        >账号绑定</v-card-title
      >
      <v-divider></v-divider>
      <v-container class="px-4 py-4" style="min-height:500px">
        <div
          class="text-caption d-flex justify-space-between align-center"
          style="height:40px;line-height:40px"
        >
          <div class="ml-3 d-flex align-center">
            <v-icon>mdi-wechat</v-icon> 微信账号：
            <v-sheet
              color="white"
              elevation="0"
              height="24"
              width="24"
              class="rounded-circle mr-1"
              v-if="wechat_name"
            >
              <v-img
                contain
                width="24"
                height="24"
                class="rounded-circle"
                :src="headimgurl"
              ></v-img>
            </v-sheet>
            <span
              :style="{
                color: wechat_name ? '' : '#9195a3'
              }"
            >
              {{ wechat_name || "未绑定" }}
            </span>
          </div>
          <v-btn v-if="!exist" text color="#399CFF" @click="doBind">
            <v-icon color="#399CFF">mdi-link-variant</v-icon> 去绑定</v-btn
          >
          <v-btn v-if="exist" text @click="unBind">
            <v-icon color="#FB8B40">mdi-link-variant-off</v-icon>
            解除绑定
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import ConfirmBox from "@/components/ConfirmBox";

export default {
  name: "BindAccount",
  props: ["externals"],
  data() {
    return {
      exist: false,
      external_id: null,
      type: null,
      wechatInfo: [],
      wechat_name: null,
      headimgurl: null
    };
  },
  created() {
    this.getExternals();
  },
  methods: {
    unBind() {
      this.$refs.confirm
        .showConfirm({
          contentText: `确认解绑微信账号：${this.wechat_name}吗？一旦解绑，将不能使用此微信账号扫码登录`,
          callback: () => {
            return this.$http
              .post(`api/auth/unbind_account`, {
                external_id: this.external_id,
                type: this.type
              })
              .delegateTo(api_request)
              .then(() => {
                this.isCloseBind = true;
                sessionStorage.clear();
                localStorage.removeItem("confirmBind");
                localStorage.removeItem("bindInfo");
                bus.$emit("newSet");
                return "解绑成功";
              })
              .catch(({ code, message }) => {
                throw `解绑失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + typeof message === "string"
                    ? message
                    : JSON.stringify(message)
                )}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    doBind() {
      this.$emit("flowEvent", "Binding");
    },
    getExternals() {
      if (this.externals.length > 0) {
        this.exist = this.externals.some(
          item => item.type === "WECHAT" || item.type === "WECHAT_RZKC"
        );
        this.wechatInfo = this.externals.filter(
          item => item.type === "WECHAT" || item.type === "WECHAT_RZKC"
        );

        if (this.wechatInfo.length > 0) {
          let {
            id,
            type,
            claims: { nickname: wechat_name, headimgurl }
          } = this.wechatInfo[0];
          this.external_id = id;
          this.type = type;
          this.headimgurl = headimgurl;
          this.wechat_name = wechat_name;
        } else {
          this.wechat_name = "";
        }
      } else {
        this.wechat_name = null;
        this.exist = false;
      }
    }
  },
  watch: {
    externals() {
      this.getExternals();
    }
  },
  components: {
    ConfirmBox
  }
};
</script>

<style></style>
