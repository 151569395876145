<template>
  <div>
    <v-card elevation="0" style="border:1px solid #EAEBEE">
      <v-card-title class="text-body-1 mx-4" style="height:70px">
        {{ needBindWechat ? "账户启用向导" : "微信账户绑定" }}
      </v-card-title>
      <v-divider></v-divider>
      <v-stepper elevation="0" v-model="e1" style="min-height:500px">
        <v-stepper-header class="px-2">
          <v-stepper-step :complete="e1 > 1" step="1">
            {{ needBindWechat ? "扫码绑定" : "步骤1" }}
          </v-stepper-step>

          <v-divider style="flex:1"></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            {{ needBindWechat ? "完成" : "步骤2" }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="py-8">
          <v-stepper-content step="1">
            <v-card elevation="0" height="260" class="mb-10">
              <!-- 微信二维码展示 -->
              <!-- WECHAT_RZKC登录 -->
              <div class="d-flex flex-column align-center">
                <div class="darken-3 mb-4 text-center">
                  微信扫码绑定
                </div>
                <div style="position:relative;">
                  <!-- 扫码成功 -->
                  <div
                    v-if="scanSuccess"
                    class="px-1 py-1 text-caption d-flex flex-column justify-center"
                    style="width:160px;height:160px;overflow:hidden;background:#fff;opacity:.9;border:1px solid #000;position:absolute;top:0;left:0"
                  >
                    <v-icon size="50" color="#07c160">mdi-check-circle</v-icon>
                    <div class="text-center text-button">
                      扫码成功
                    </div>
                  </div>
                  <!-- 二维码已过期 -->
                  <div
                    v-if="isValid"
                    class="px-1 py-1 text-caption d-flex flex-column justify-center"
                    style="width:160px;height:160px;overflow:hidden;background:#fff;opacity:.9;border:1px solid #000;position:absolute;top:0;left:0"
                  >
                    <v-icon size="50" color="#F5A623">mdi-alert-circle</v-icon>
                    <div class="text-center text-button mt-2">
                      二维码已过期
                      <span
                        @click="toRefresh"
                        class="blue--text"
                        style="cursor: pointer;"
                        >刷新</span
                      >
                    </div>
                  </div>
                  <!-- 绑定失败  -->
                  <div
                    v-if="rzkcCode"
                    class="px-1 py-1 text-caption d-flex flex-column justify-center"
                    style="width:160px;height:160px;overflow:hidden;background:#fff;opacity:.9;border:1px solid #000;position:absolute;top:0;left:0"
                  >
                    <v-icon size="50" color="#F5A623">mdi-alert-circle</v-icon>
                    <div class="text-center text-button mt-2">
                      绑定失败
                      <span
                        @click="toRefresh"
                        class="blue--text"
                        style="cursor: pointer;"
                        >再试一次</span
                      >
                    </div>
                  </div>
                  <div
                    class="px-1 py-1 text-caption d-flex flex-row justify-center"
                    style="width:160px;height:160px;overflow:hidden;border:1px solid #000;background:#fff;"
                    id="wechat_code"
                    ref="wechat_code"
                  ></div>
                </div>

                <div
                  class="mt-2 d-flex flex-row justify-center"
                  style="color:#777;"
                >
                  请使用微信扫码
                </div>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card elevation="0" class="mb-10" height="260px">
              <div class="d-flex flex-column align-center">
                <v-sheet
                  color="white"
                  elevation="2"
                  height="80"
                  width="80"
                  class="mt-4 rounded-circle"
                >
                  <v-img
                    contain
                    width="80"
                    height="80"
                    class="rounded-circle"
                    :src="
                      headimgurl ? headimgurl : require('@/assets/avatar.png')
                    "
                  ></v-img>
                </v-sheet>
                <div
                  class=" mt-6 text-subtitle-1 text-center"
                  style="font-weight:600"
                  v-if="login_name || nickname || fullname"
                >
                  {{ login_name || nickname || fullname }}
                </div>
                <div class="mt-6 text-subtitle-1 text-center">
                  <div>你即将绑定此微信账号</div>
                  <div>后续可以使用此微信账号登录</div>
                </div>
              </div>
            </v-card>
            <div class="d-flex justify-center">
              <v-btn color="primary" depressed @click="doBind">
                确认绑定
              </v-btn>

              <v-btn class="ml-4" depressed @click="cancelBind">
                重新扫码
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { api_request } from "@/util/network";
import { randomSessionId } from "@/util/util";
import bus from "@/util/eventBus";

export default {
  name: "Binding",
  props: ["identity", "curflow"],
  data() {
    return {
      e1: "1",
      validTimer: 60,
      timer: null,
      isValid: false,
      scanSuccess: false,
      rzkcCode: null,
      fullname: null,
      login_name: null,
      nickname: null,
      headimgurl: null,
      needBindWechat: null
    };
  },
  created() {
    let bindData = JSON.parse(localStorage.getItem("bindData"));
    let needBindWechat = localStorage.getItem("bindWechat");
    if (needBindWechat) {
      this.needBindWechat = true;
    }
    if (bindData) {
      bindData.identity_token ? (this.e1 = "2") : (this.e1 = "1");
    }
    this.showWechatCode();
  },
  methods: {
    getIdentity() {
      let { name, claims } = this.identity;
      this.personInfo = claims;
      this.name = name;
      if (claims) {
        this.login_name = claims.login_name;
        this.nickname = claims.nickname;
        this.fullname = claims.fullname;
      }
    },
    // 确定绑定
    doBind() {
      let bindInfoData = JSON.parse(localStorage.getItem("bindData"));
      let payload = {};
      if (bindInfoData) {
        payload = bindInfoData;
      } else {
        let { token } = JSON.parse(localStorage.getItem("bindInfo"));
        payload = {
          session_id: localStorage.getItem("session_id"),
          identity_token: token
        };
      }
      return this.$http
        .post(`api/auth/bind_account`, payload)
        .delegateTo(api_request)
        .then(data => {
          if (data) {
            window.location = data["return_url"];
          } else {
            localStorage.removeItem("bindData");
            localStorage.removeItem("bindWechat");
            bus.$emit("newSet");
            this.$emit("flowEvent", "BindAccount");
            return "绑定成功";
          }
        })
        .catch(({ code, message }) => {
          localStorage.removeItem("session_id");
          localStorage.removeItem("bindData");
          throw `绑定失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 取消绑定
    async cancelBind() {
      sessionStorage.clear();
      localStorage.removeItem("bindInfo");
      localStorage.removeItem("bindData");
      localStorage.removeItem("session_id");
      this.e1 = 1;
      this.timer = 60;
      this.scanSuccess = false;
      document.getElementById("wechat_code").innerHTML = "";
      this.showWechatCode();
    },
    async showWechatCode() {
      let session = await randomSessionId();
      let sessionCode = localStorage.getItem("session_id");
      let originName = window.location.origin;
      if (sessionCode) {
        new QRCode(
          this.$refs.wechat_code,
          `${originName}${process.env.VUE_APP_BASE_URL}/wechat/authorize?session=${sessionCode}`
        );
        this.valid();
        return;
      } else {
        localStorage.setItem("session_id", session);
        new QRCode(
          this.$refs.wechat_code,
          `${originName}${
            process.env.VUE_APP_BASE_URL
          }/wechat/authorize?session=${localStorage.getItem("session_id")}`
        );
        this.valid();
      }
    },
    consumeSession() {
      return new Promise(resolve => {
        return this.$http
          .post(`api/auth/consume_session`, {
            session_id: localStorage.getItem("session_id")
          })
          .delegateTo(api_request)
          .then(data => {
            resolve(data);
          })
          .catch(({ code, message }) => {
            if (code === "AccountIdConflicted") {
              localStorage.removeItem("session_id");
              clearInterval(this.timer);
              this.validTimer = 60;
              this.rzkcCode = "AccountIdConflicted";
              throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                "api." + typeof message === "string"
                  ? message
                  : JSON.stringify(message)
              )}`;
            }
          })
          .delegateTo(this.$snackbar.delegateError);
      });
    },
    valid() {
      this.timer = setInterval(async () => {
        if (this.validTimer > 0) {
          this.validTimer = this.validTimer - 1;
          if (this.validTimer % 3 === 0) {
            let res = await this.consumeSession();
            if (res.identity_token) {
              clearInterval(this.timer);
              this.scanSuccess = true;

              let {
                claims: { headimgurl, nickname }
              } = res.identity;
              this.headimgurl = headimgurl;
              this.nickname = nickname;
              localStorage.setItem(
                "bindInfo",
                JSON.stringify({
                  token: res.identity_token,
                  headimgurl,
                  nickname
                })
              );
              this.e1 = "2";
            }
          }
        } else {
          clearInterval(this.timer);
          this.isValid = true;
          localStorage.removeItem("session_id");
        }
      }, 1000);
    },
    toRefresh() {
      this.isValid = false;
      this.rzkcCode = null;
      this.validTimer = 60;
      this.scanSuccess = false;
      document.getElementById("wechat_code").innerHTML = "";
      this.showWechatCode();
    },
    confirmBindPage() {
      let bindInfo = JSON.parse(localStorage.getItem("bindInfo"));
      if (bindInfo) {
        let { headimgurl, nickname } = bindInfo;
        this.headimgurl = headimgurl;
        this.nickname = nickname;
      } else {
        let {
          identity: { headimgurl, nickname }
        } = JSON.parse(localStorage.getItem("bindData"));
        this.headimgurl = headimgurl;
        this.nickname = nickname;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    localStorage.removeItem("session_id");
  }
};
</script>

<style lang="less" scoped>
.v-stepper {
  /deep/ .v-stepper__header {
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

/deep/ .v-stepper__label {
  display: block !important;
}

/deep/ .v-stepper {
  box-shadow: none;
}
</style>
